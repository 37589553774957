import React, { useEffect, useState } from 'react';
import { Box, ButtonGroup } from '@mui/material';
import { RedButton } from '../../../components/CommonComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './style.scss'; // Import the CSS file for styling
import { getOptimizedBDFImage } from '../../../utils/image-helper';

const ViewType = {
  Carts: 'Carts',
  Wishlists: 'Wishlists',
};

export default function OnlineCartsWishlists({
  parentModalRef,
  cartData,
  wishData,
}) {
  const [viewType, setViewType] = useState(ViewType.Carts);
  const [cartDataList, setCartDataList] = useState([]);
  const [wishDataList, setWishDataList] = useState([]);

  useEffect(() => {
    setCartDataList(cartData);
    setWishDataList(wishData);
  }, [cartData, wishData]);

  const addDefaultSrc = (ev) => {
    ev.target.src = '/img/noimg2.png'; // Fallback image source
  };

  return (
    <Box className="cart-wishlist">
      <ButtonGroup>
        <RedButton
          label="Online Cart"
          outline={viewType !== ViewType.Carts}
          onClick={() => setViewType(ViewType.Carts)}
          style={{ marginTop: '0' }}
        />
        <RedButton
          label="Wishlist"
          outline={viewType !== ViewType.Wishlists}
          onClick={() => setViewType(ViewType.Wishlists)}
          style={{ marginTop: '0' }}
        />
      </ButtonGroup>

      <div className="cart-wishlist-body-display">
        {viewType === ViewType.Carts && (
          <div className="carts-display">
            {cartDataList.length === 0 ? (
              <div className="no-data">No data available</div>
            ) : (
              <table className="styled-table">
                <thead>
                  <tr>
                    <th style={{ width: '100px' }}></th>
                    <th style={{ width: 'auto' }}>Item</th>
                    <th style={{ width: '150px' }}>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {cartDataList.map((item, index) => (
                    <tr key={index}>
                      <td className="image">
                        <LazyLoadImage
                          src={getOptimizedBDFImage(item?.product?.sku)}
                          onError={addDefaultSrc}
                        />
                      </td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.price?.formattedValue}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}

        {viewType === ViewType.Wishlists && (
          <div className="wishlist-display">
            {wishDataList.length === 0 ? (
              <div className="no-data">No data available</div>
            ) : (
              <table className="styled-table">
                <thead>
                  <tr>
                    <th style={{ width: '100px' }}></th>
                    <th style={{ width: 'auto' }}>Item</th>
                    <th style={{ width: '150px' }}>Price</th>
                    {/* <th>Availability</th> */}
                  </tr>
                </thead>
                <tbody>
                  {wishDataList.map((item, index) => (
                    <tr key={index}>
                      <td className="image">
                        <LazyLoadImage
                          src={getOptimizedBDFImage(item?.product?.sku)}
                          onError={addDefaultSrc}
                        />
                      </td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.price?.formattedValue}</td>
                      {/* <td>
                        {item?.product?.atpText === null
                          ? 'Available Now'
                          : item?.product?.atpText}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </Box>
  );
}
