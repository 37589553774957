// Constants for image URLs and configurations
export const IMAGE_CONSTANTS = {
  CLOUDINARY_BASE_URL: 'https://res.cloudinary.com/mybobs/image/upload',
  GALLERY_SUFFIX: '_gallery_01_wide.jpg',
  DEFAULT_PLACEHOLDER: '/img/noimg2.png',
};

/**
 * Get optimized image URL with transformations from Cloudinary
 * @param {string} sku - Product SKU
 * @param {object} options - Image options
 * @param {string} options.size - Size preset ('small', 'medium', 'large')
 * @param {number} options.width - Custom width in pixels
 * @param {number} options.height - Custom height in pixels
 * @param {string} options.format - Image format ('auto', 'webp', 'jpg', 'png')
 * @param {boolean} options.lowQuality - Whether to use low quality for preview
 * @param {string} options.fallbackUrl - URL to use if primary image fails
 * @returns {string} - Optimized image URL
 */
export const getOptimizedBDFImage = (sku, options = {}) => {
  if (!sku) return IMAGE_CONSTANTS.DEFAULT_PLACEHOLDER;

  const {
    size,
    width,
    height,
    format = 'auto',
    lowQuality = false,
    fallbackUrl = IMAGE_CONSTANTS.DEFAULT_PLACEHOLDER,
  } = options;

  // Define size presets
  const sizeMap = {
    small: { width: 200, height: 150 },
    medium: { width: 400, height: 300 },
    large: { width: 800, height: 600 },
  };

  // Build transformation parameters
  let transformations = [];

  // Add size transformations
  if (width || height) {
    if (width) transformations.push(`w_${width}`);
    if (height) transformations.push(`h_${height}`);
  } else if (sizeMap[size]) {
    transformations.push(`w_${sizeMap[size].width}`);
    transformations.push(`h_${sizeMap[size].height}`);
  }

  // Add format transformation
  if (format !== 'auto') {
    transformations.push(`f_${format}`);
  }

  // Add quality transformation for low quality preview
  if (lowQuality) {
    transformations.push('q_30');
  }

  // Add loading optimization
  transformations.push('fl_progressive');

  // Combine transformations
  const transformationString =
    transformations.length > 0 ? transformations.join(',') + '/' : '';

  try {
    return `${IMAGE_CONSTANTS.CLOUDINARY_BASE_URL}/${transformationString}${sku}${IMAGE_CONSTANTS.GALLERY_SUFFIX}`;
  } catch (error) {
    console.error('Error generating image URL:', error);
    return fallbackUrl;
  }
};

/**
 * Asynchronously preload an image
 * @param {string} src - Image source URL
 * @returns {Promise} - Promise that resolves when image is loaded or rejects on error
 */
export const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(src);
    img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    img.src = src;
  });
};

/**
 * Asynchronously get an optimized image with preloading
 * @param {string} sku - Product SKU
 * @param {object} options - Same options as getOptimizedBDFImage
 * @returns {Promise<string>} - Promise that resolves to the image URL
 */
export const getOptimizedBDFImageAsync = async (sku, options = {}) => {
  if (!sku) return IMAGE_CONSTANTS.DEFAULT_PLACEHOLDER;

  const imageUrl = getOptimizedBDFImage(sku, options);

  try {
    // Attempt to preload the image
    await preloadImage(imageUrl);
    return imageUrl;
  } catch (error) {
    console.error('Error preloading image:', error);

    // If fallback URL is provided, try to preload that
    if (options.fallbackUrl) {
      try {
        await preloadImage(options.fallbackUrl);
        return options.fallbackUrl;
      } catch (fallbackError) {
        console.error('Error preloading fallback image:', fallbackError);
      }
    }

    // Return default placeholder if all else fails
    return IMAGE_CONSTANTS.DEFAULT_PLACEHOLDER;
  }
};

/**
 * React component props for lazy-loaded images
 * @param {string} sku - Product SKU
 * @param {object} options - Same options as getOptimizedBDFImage
 * @param {object} imgProps - Additional props to pass to the LazyLoadImage component
 * @returns {object} - Props object for LazyLoadImage component
 */
export const getLazyImageProps = (sku, options = {}, imgProps = {}) => {
  if (!sku) return { src: IMAGE_CONSTANTS.DEFAULT_PLACEHOLDER, ...imgProps };

  // Generate low quality placeholder for blur-up effect
  const placeholderSrc = getOptimizedBDFImage(sku, {
    ...options,
    width: 40,
    height: 30,
    lowQuality: true,
  });

  // Generate main image
  const src = getOptimizedBDFImage(sku, options);

  return {
    src,
    placeholderSrc,
    effect: 'blur',
    wrapperClassName: 'lazy-load-image-wrapper',
    onError: (e) => {
      // If main image fails, try fallback
      if (options.fallbackUrl) {
        e.target.src = options.fallbackUrl;
      } else {
        e.target.src = IMAGE_CONSTANTS.DEFAULT_PLACEHOLDER;
      }
    },
    ...imgProps,
  };
};

/**
 * Get the original BDF image URL (for backward compatibility)
 * @param {string} sku - Product SKU
 * @returns {string} - Original image URL
 */
export const getBDFImage = (sku) => {
  return `${IMAGE_CONSTANTS.CLOUDINARY_BASE_URL}/${sku}${IMAGE_CONSTANTS.GALLERY_SUFFIX}`;
};

/**
 * Get a BDF color image URL
 * @param {string} color - Color name (e.g. 'red', 'blue', etc.)
 * @returns {string} - URL of the color image
 */
export const getBDFColors = (color) => {
  if (!color)
    return `${IMAGE_CONSTANTS.CLOUDINARY_BASE_URL}/white_color_large.jpg`;

  // Handle special color name mappings
  const colorMappings = {
    'ash gray': 'ash',
    multicolor: 'multicolored',
    'not applicable': 'black',
    default: 'white',
  };

  // Normalize the color name (lowercase and trim)
  const normalizedColor = color.toLowerCase().trim();

  // Use the mapping if it exists, otherwise use the normalized color
  const mappedColor = colorMappings[normalizedColor] || normalizedColor;

  return `${IMAGE_CONSTANTS.CLOUDINARY_BASE_URL}/${mappedColor}_color_large.jpg`;
};
