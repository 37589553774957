import React, { Component, createContext } from 'react';

// Create a context
const GaugeContext = createContext();

class GaugeProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: {
        label: '',
        value: '',
      }, // Initial dropdown value
      setSelectedOption: this.setSelectedOption, // Function to update the value
    };
  }

  setSelectedOption = (newOption) => {
    this.setState({ selectedOption: newOption });
  };

  render() {
    return (
      <GaugeContext.Provider value={this.state}>
        {this.props.children}
      </GaugeContext.Provider>
    );
  }
}

export { GaugeProvider, GaugeContext };
