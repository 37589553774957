import $ from 'jquery';
//import {version} from '../../package.json'

const config = require('../config');
const version = require('../version');
const gitTag = require('../tag');

//console.log("PROCESS", version)

export const getVersion = () => {
  localStorage.setItem('Tag', gitTag.gitTag || '');
  return version.version.build;
};
export const getSprint = () => {
  let ver = getVersion().trim().split('.');
  let sprint = parseInt(ver[1] || 0);
  return sprint;
};

export const getAPIVersion = () => {
  return localStorage.getItem('appVersion') || '';
};
export const getUI_NEW_ORDER_API = () => {
  let parameters = JSON.parse(localStorage.getItem('Parameters'));
  return parseInt(parameters.UI_NEW_ORDER_API || 0);
};

export const isExternal = () => window?.isExternal || false;
export const extSource = () => localStorage.getItem('external_source');
export const extEmpId = () => localStorage.getItem('external_EmpId');
export const extUser = () =>
  JSON.parse(localStorage.getItem('external_user')) || {};

export const isTrainer = () =>
  localStorage.getItem('isTrainer') === 'true' ? true : false;
export const getEmplId = () => localStorage.getItem('EmplId') || '';
export const getStorelId = () => localStorage.getItem('StoreId') || '';
export const getOutletId = () => localStorage.getItem('OutletId') || '';
export const getUserGroup = () =>
  parseInt(localStorage.getItem('UserGroupId')) || 6;

export const getSnrGXSJobTitles = () => {
  return [
    'Senior Retail Guest Experience Specialist',
    'Senior Retail Guest Experience Specialist I',
    'Senior Retail Guest Experience Specialist II',
    'Senior Retail Guest Experience Specialist III',
    'Senior Retail Guest Experience Specialist 1',
    'Senior Retail Guest Experience Specialist 2',
    'Senior Retail Guest Experience Specialist 3',
  ];
};

export const getDrillsCategory = () => [
  { label: 'Connecting', value: 'Connecting' },
  { label: 'Uncovering needs', value: 'Uncovering needs' },
  { label: 'Providing solutions', value: 'Providing solutions' },
  { label: 'Closing', value: 'Closing' },
  { label: 'Follow-up', value: 'Follow-up' },
  { label: 'Other', value: 'Other' },
];

export const getDetailSelection = () => [
  { label: 'I Saw You...', value: 'I saw you' },
  { label: 'I Heard You...', value: 'I heard you' },
  { label: 'Other', value: 'Other' },
];

export const getDivision = () => {
  let division = JSON.parse(
    localStorage.getItem('Parameters'),
  )?.GUEST_SUPPORT_DIVISION;

  if (division) {
    let divisionArray = division.split(',');

    let divisionInfo = divisionArray.map((x) => {
      return { divisionName: `Division ${x}`, division: x };
    });

    let divisionInfoDropdown = divisionInfo.map((x) => {
      return { label: x.divisionName, value: x.division };
    });

    return { divisionInfo, divisionInfoDropdown };
  }
};

export const getAPIHost = () => process.env.REACT_APP_API;
export const getSignalRHost = () => process.env.REACT_APP_SIGNALR;
export const getAPISSOHost = () => {
  let finalHost = process.env.REACT_APP_API;
  console.log('SSO Host: ' + finalHost);
  return finalHost;
};
export const getAPISSOHostLegacy = () => {
  let finalHost = process.env.REACT_APP_SSO_LEGACY;
  console.log('SSO Host (Legacy): ' + finalHost);
  return finalHost;
};
export const getLandingPage = () =>
  `${process.env.REACT_APP_LANDING_PAGE}?sso=${Math.random()}`;

export const apiHeaders = () => {
  if (isExternal()) {
    return {
      headers: {
        Authorization: localStorage.getItem('external_auth'),
        appToken: localStorage.getItem('external_token'),
        source: localStorage.getItem('external_source'),
        ...config.api.headers,
      },
    };
  } else {
    return {
      headers: {
        Authorization: localStorage.getItem('authentication'),
        appToken: localStorage.getItem('appToken'),
        source: 'bobboost',
        ...config.api.headers,
      },
    };
  }
};
export const getEnvironment = (type) => {
  if (type === '' || !type) {
    return process.env.REACT_APP_ENV === 'PROD'
      ? ''
      : process.env.REACT_APP_ENV;
  } else {
    let name =
      process.env.REACT_APP_ENV === 'PROD' ? '' : process.env.REACT_APP_ENV;
    if (name === 'ISS') name = 'issdev';
    return name.toLowerCase();
  }
};

export const isLocal = () =>
  process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'ISS';
export const isDEV = () => process.env.REACT_APP_ENV === 'DEV';
export const isQA = () => process.env.REACT_APP_ENV === 'QA';
export const isProd = () => process.env.REACT_APP_ENV === 'PROD';

export const touchToggle = () => {
  let oriValue = getTouch();
  setTouch(!oriValue);
  return oriValue;
};
export const setTouch = (option) => {
  localStorage.setItem('touch', option);
};
export const getTouch = () => localStorage.getItem('touch') === 'true' || false;
export const onTouchFieldScroll = (target, offsetTop) => {
  if (getTouch()) {
    let bodyRect = document.body.getBoundingClientRect().top;
    let elementRect = target.getBoundingClientRect().top;
    let elementPosition = elementRect - bodyRect;
    let offsetPosition = elementPosition - offsetTop;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

export const checkAPIVersion = async () => {
  let check = false;
  let currentTime = parseInt(new Date().getTime() / 1000);
  let lastCheck = parseInt(localStorage.getItem('versionLastCheck') || 0);

  if (lastCheck === 0) {
    localStorage.setItem('versionLastCheck', currentTime);
  } else {
    if (currentTime - 3600 > lastCheck) {
      check = true;
    }
  }

  if (check) {
    try {
      console.log('check version');
      await fetch(getAPIHost() + '/getVersion', {
        method: 'GET',
        ...apiHeaders(),
      }).then(async (data) => {
        const json = await data.json();
        console.log(data);
        console.log(json);

        if (json.result.version !== localStorage.getItem('appVersion')) {
          alert('New version of app detected. Please re-login to BobBoost!');
        }

        localStorage.setItem('versionLastCheck', currentTime);
      });
    } catch (r) {
      console.log('Check version ');
      console.log(r);
    }
  }
};

export const checkVersionScheduller = () => {
  let checkCount = parseInt(localStorage.getItem('versionCheckCount') || 0);
  localStorage.setItem('versionCheckCount', checkCount++);

  checkAPIVersion();

  setTimeout(() => checkVersionScheduller(), 3600 * 1000);
};

export const formatNumber = (number, decimal, prefix, postfix) => {
  if (isNaN(number)) number = 0;
  number = parseFloat(number);
  if (!prefix) prefix = '';
  if (!postfix) postfix = '';
  return (
    prefix +
    number.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    }) +
    postfix
  );
};

export const formatNumber2 = (number, digit) => {
  if (isNaN(number)) number = 0;
  let digitStr = '';
  for (let i = 1; i < digit; i++) {
    digitStr = digitStr + '0';
  }
  var formattedNumber = (digitStr + number).slice(-digit);
  return formattedNumber;
};

export const getMonthName = (number) => {
  let months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[number - 1];
};

export const getDayName = (number) => {
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return days[number];
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const getQSParam = (key) => {
  let qs = new URLSearchParams(window.location.search);
  return qs.get(key);
};

export const getCoachingFiscalMonths = (type) => {
  let currentMonth = {};
  let fiscalMonthList = [];
  let fiscalMonthCheckbox = [];

  if (!type) type = 'coaching';
  if (localStorage.getItem('FiscalCurrentMonth')) {
    currentMonth = JSON.parse(localStorage.getItem('FiscalCurrentMonth'));
    currentMonth.number = zeroPad(currentMonth.number, 2);

    if (type === 'coaching') {
      let tempMonth = JSON.parse(localStorage.getItem('FiscalMonthsCoaching'));
      let showNextMonth = false;
      let curMth =
        currentMonth.fiscalYear * 100 + parseInt(currentMonth.number);
      fiscalMonthList = [];

      tempMonth.map((m) => {
        let thisMth = m.fiscalYear * 100 + parseInt(m.number);

        if (thisMth <= curMth || showNextMonth) {
          fiscalMonthList.push(m);
          showNextMonth = false;
        }

        if (thisMth === curMth) {
          let endOfMonth = new Date(
            m.fiscalMonthEnd.split('/')[2],
            m.fiscalMonthEnd.split('/')[0] - 1,
            m.fiscalMonthEnd.split('/')[1],
          );
          endOfMonth.setDate(endOfMonth.getDate() - 6);

          if (new Date().getTime() >= endOfMonth.getTime()) {
            showNextMonth = true;
          }
        }
        return m;
      });
    } else if (type === 'year') {
      //fiscalMonthList = JSON.parse(localStorage.getItem("FiscalMonths"));

      let tempMonth = JSON.parse(localStorage.getItem('FiscalMonths'));
      let showNextMonth = false;
      let curMth =
        currentMonth.fiscalYear * 100 + parseInt(currentMonth.number);
      fiscalMonthList = [];

      tempMonth.map((m) => {
        let thisMth = m.fiscalYear * 100 + parseInt(m.number);

        if (thisMth <= curMth || showNextMonth) {
          fiscalMonthList.push(m);
          showNextMonth = false;
        }

        if (thisMth === curMth) {
          let endOfMonth = new Date(
            m.fiscalMonthEnd.split('/')[2],
            m.fiscalMonthEnd.split('/')[0] - 1,
            m.fiscalMonthEnd.split('/')[1],
          );
          endOfMonth.setDate(endOfMonth.getDate() - 6);

          if (new Date().getTime() >= endOfMonth.getTime()) {
            showNextMonth = true;
          }
        }
        return m;
      });

      if (showNextMonth) {
        let futureFiscalMonth = JSON.parse(
          localStorage.getItem('FiscalFutureMonths'),
        );
        fiscalMonthList.push(futureFiscalMonth[0]);
      }
    }

    for (let m of fiscalMonthList) {
      m.number = zeroPad(m.number, 2);
    }
  } else {
    let d = new Date();
    let month = [];
    month[0] = 'January';
    month[1] = 'February';
    month[2] = 'March';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'June';
    month[6] = 'July';
    month[7] = 'August';
    month[8] = 'September';
    month[9] = 'October';
    month[10] = 'November';
    month[11] = 'December';

    currentMonth = {
      fiscalYear: d.getFullYear(),
      name: month[d.getMonth()],
      number: zeroPad(d.getMonth() + 1, 2),
    };

    if (type === 'coaching') {
      for (let i = -3; i <= 0; i++) {
        let nd = new Date();
        nd.setMonth(i + d.getMonth());
        fiscalMonthList.push({
          fiscalYear: nd.getFullYear(),
          name: month[nd.getMonth()],
          number: zeroPad(nd.getMonth() + 1, 2),
        });
      }
    } else if (type === 'year') {
      for (let i = -11; i <= 0; i++) {
        let nd = new Date();
        nd.setMonth(i + d.getMonth());
        fiscalMonthList.push({
          fiscalYear: nd.getFullYear(),
          name: month[nd.getMonth()],
          number: zeroPad(nd.getMonth() + 1, 2),
        });
      }
    }
  }

  //Sort by latest month to oldest month
  fiscalMonthList.sort((a, b) => {
    return (
      b.fiscalYear - a.fiscalYear || parseInt(b.number) - parseInt(a.number)
    );
  });

  //console.log("fiscalmonthlist", fiscalMonthList)

  for (let m of fiscalMonthList) {
    fiscalMonthCheckbox.push({
      label: m.name + ' ' + m.fiscalYear,
      value: m.number + '-' + m.fiscalYear,
    });
  }

  return {
    currentMonth: currentMonth,
    currentMonthValue: currentMonth.number + '-' + currentMonth.fiscalYear,
    monthList: fiscalMonthList,
    monthListSelect: fiscalMonthCheckbox,
    currentMonthSelect: {
      label: currentMonth.name + ' ' + currentMonth.fiscalYear,
      value: currentMonth.number + '-' + currentMonth.fiscalYear,
    },
  };
};

export const shortNumber = (n, d) => {
  if (n < 1000) return parseFloat(n).toFixed(d);
  else if (n < 1000000) return parseFloat(n / 1000).toFixed(d) + 'K';
  else return parseFloat(n / 1000000).toFixed(d) + 'M';
};

//Follow SalesPerformance API payload response naming
export const KPIType = {
  ws: 'writtenSales',
  ds: 'deliveredSales',
  cr: 'closeRate',
  aov: 'averageTicket',
  twa: 'wellsPreApprove',
  ga: 'guardianAttach',
  pa: 'protectorAttach',
  mp: 'mattressPenetration',
  nps: 'nps',
  soc: 'salesOrderCount',
  upt: 'unitPt',
  spch: 'salesPerCallHandle',
};

//BCP-1817
export const KPIColoringLogic = (
  kpiType,
  goal,
  actual,
  performance,
  intelligentPerformance,
  calendar,
) => {
  let replaceRegex = /[%,$]/g;

  let goalVal = parseFloat(goal?.toString().replace(replaceRegex, ''));
  let actualVal = parseFloat(actual?.toString().replace(replaceRegex, ''));
  let performanceVal = performance;
  let intelligentPerformanceVal = intelligentPerformance;

  // console.log("Here")
  // console.log("calendar", calendar)
  // console.log("kpiType", kpiType)
  // console.log("goal", goalVal)
  // console.log("actual", actualVal)
  // console.log("performanceVal", performance)
  // console.log("intelligentPerformanceVal", intelligentPerformanceVal)

  let coloringRed = {
    colorHex: '#FF7285',
    colorClass: 'low',
  };
  let coloringYellow = {
    colorHex: '#ffda83',
    colorClass: 'high',
  };
  let coloringGreen = {
    colorHex: '#7CD89C',
    colorClass: 'fulfill',
  };

  let returnVal = { colorHex: '', colorClass: '' };

  switch (kpiType) {
    // case KPIType.nps:
    // case KPIType.ga:
    //   //By value rather than performance
    //   if (actualVal >= goalVal) {
    //     returnVal = coloringGreen
    //   }
    //   else if (actualVal >= goalVal - 10 && actualVal < goalVal) {
    //     returnVal = coloringYellow
    //   }
    //   else {
    //     returnVal = coloringRed
    //   }
    //   break;
    case KPIType.ws:
    case KPIType.ds:
    case KPIType.twa:
      //By performance per day
      let fiscalData = '';
      if (calendar === undefined || calendar === null) {
        //If not calDate passed, then use current fiscal month
        fiscalData = JSON.parse(localStorage.getItem('FiscalCurrentMonth'));
      } else if (calendar !== undefined && calendar !== null) {
        fiscalData = calendar?.lastDayOfMonth;
      }

      let numberOfDays = fiscalData?.numbersOfDays;
      let fiscalDayOfMonth = fiscalData?.fiscalDayOfMonth;

      //For testing only
      //actualVal = 15000; //Should be green
      //actualVal = 14000; //Should be yellow
      //actualVal = 10500; //Should be red
      //if (kpiType === KPIType.twa) { actualVal = 1.4 }

      let perDayGoal = goalVal / numberOfDays;
      let perDayActual = actualVal / fiscalDayOfMonth;

      // console.log("numberOfDays", numberOfDays)
      // console.log("fiscalDayOfMonth", fiscalDayOfMonth)
      // console.log("goalManual", goalVal)
      // console.log("actualManual", actualVal)
      // console.log("perDayGoal", perDayGoal)
      // console.log("perDayActual", perDayActual)
      // console.log("perDayGoal/perDayActual", (perDayActual / perDayGoal) * 100)

      if (perDayActual >= perDayGoal) {
        //If perDayActual is more than perDayGoal then green
        returnVal = coloringGreen;
      } else if ((perDayActual / perDayGoal) * 100 >= 95) {
        //If perDayActual is more than 95% then yellow
        returnVal = coloringYellow;
      } else {
        returnVal = coloringRed;
      }
      break;
    default:
      let valueToUse = 0;
      //To ignore intelligentPerformanceVal coloring at the moment //BCP-1817
      intelligentPerformance = -1;

      //Determine which performance value to be use
      if (
        intelligentPerformanceVal &&
        intelligentPerformanceVal > 0 &&
        intelligentPerformanceVal > performanceVal
      ) {
        valueToUse = intelligentPerformanceVal;
      } else {
        valueToUse = performanceVal;
      }

      if (valueToUse >= 100) {
        returnVal = coloringGreen;
      } else if (valueToUse >= 90 && valueToUse < 100) {
        returnVal = coloringYellow;
      } else {
        returnVal = coloringRed;
      }
      break;
  }

  //console.log(returnVal)
  return returnVal;
};

export const getConditionColor = (n, c) => {
  if (!c) {
    c = {
      0: '#FF7285',
      80: '#ffda83',
      100: '#7CD89C',
    };
  }
  let ret = '#FF7285';
  for (let i in c) {
    if (n >= parseInt(i)) ret = c[i];
  }
  return ret;
};

export const getConditionColorClass = (n, c) => {
  if (!c) {
    c = {
      0: 'low',
      80: 'high',
      100: 'fulfill',
    };
  }
  let ret = 'low';
  for (let i in c) {
    if (n >= parseInt(i)) ret = c[i];
  }
  return ret;
};

export const cmp = function (a, b, direction = 'asc') {
  if (direction !== 'asc') {
    if (a < b) return 1;
    if (a > b) return -1;
  } //Descending order
  else {
    if (a > b) return 1;
    if (a < b) return -1;
  }
  return 0;
};

export const sort = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a[key] || !b[key]) {
      // property doesn't exist on either object
      return 0;
    }

    const varA =
      typeof a[key] === 'string' ? a[key].toUpperCase() : a[key] || '';
    const varB =
      typeof b[key] === 'string' ? b[key].toUpperCase() : b[key] || '';

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export const dateYYYYMMDD = (str) => {
  let s = str.substr(0, 10).split('-');
  return new Date(s[0], parseInt(s[1]) - 1, s[2], 0, 0, 0, 0);
};

export const formatDate = (str) => {
  // from YYYY-MM-DD
  if (str === '' || str === null || str === undefined) return '';

  // Handle "DD/M/YYYY HH:MM:SS AM/PM" format (e.g., "22/3/2025 12:00:00 AM")
  if (/^\d{1,2}\/\d{1,2}\/\d{4}\s+\d{1,2}:\d{2}:\d{2}\s+[AP]M$/.test(str)) {
    const parts = str.split(' ')[0].split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    return zeroPad(month, 2) + '/' + zeroPad(day, 2) + '/' + year; // return mm/dd/yyyy
  } else if (str.indexOf('T') > 0 || str.indexOf(' ') > 0) {
    // for date with timezone
    let d = new Date(str);
    return (
      zeroPad(d.getMonth() + 1, 2) +
      '/' +
      zeroPad(d.getDate(), 2) +
      '/' +
      d.getFullYear()
    ); // return mm/dd/yyyy
  } else {
    // for date only
    let s = str.split('-');
    return s[1] + '/' + s[2] + '/' + s[0]; // return mm/dd/yyyy
  }
};

export const getTime = (str) => {
  if (str === '' || str === null || str === undefined) return '';
  else if (str.indexOf('T') > 0 || str.indexOf(' ') > 0) {
    var today = new Date(str),
      // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      time = today.toLocaleString('en-US', { hour12: true });
    return time;
  }
};

export const getTimeforDate = (str) => {
  if (str === '' || str === null || str === undefined) return '';
  else if (str.indexOf('T') > 0 || str.indexOf(' ') > 0) {
    var today = new Date(str),
      // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      time = today.toLocaleString('en-US', { hour12: true });
    return time;
  }
};

export const arrayRemoveNull = (array) => {
  let newArray = array.filter((item) => {
    return item;
  });
  return newArray;
};

export const inputFocus = () => {
  //document.querySelector(".cr-page").style.marginBottom = "30vh"
};

export const inputBlur = () => {
  //document.querySelector(".cr-page").style.marginBottom = ""
};

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj[prop]) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const phoneNumberFormat = (str) => {
  if (str.indexOf('-') === 3 && str.indexOf('-', 4) === 7) return str;
  else {
    var cleaned = ('' + str).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return null;
  }
};

export const phoneNumberFormat2 = (str) => {
  if (str.indexOf('-') === 3 && str.indexOf('-', 4) === 7) return str;
  else {
    var cleaned = ('' + str).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  }
};

export const bucketMerge = (bucketOrders, buckets, bucketType, userGroupId) => {
  if (!bucketOrders || bucketOrders.length === 0) return buckets;

  let custBuckets = [];
  if (bucketOrders.length > 0) {
    // to merge
    let custList = [];

    let checkDup = [];
    bucketOrders.map((b) => {
      if (b.bucketType === bucketType && b.userGroupId === userGroupId) {
        // remove duplicate
        if (!checkDup.includes(b.bucketID)) custList.push(b);
        checkDup.push(b.bucketID);
      }
      return b;
    });

    console.log('Ori List');
    console.log(custList);
    console.log(buckets);
    let newBuckets = []; // temp list

    if (custList.length === 0) return buckets;

    // merge default and customized
    // Find BUCKET (root)
    buckets.map((b) => {
      if (b.menuType === 'BUCKET') {
        newBuckets[b.uid] = [];
        b.tempSeq = 0; // sort later
        newBuckets[b.uid].push(b);
      }
      return b;
    });

    // Find children
    buckets.map((b) => {
      if (b.menuType !== 'BUCKET') {
        let found = false;
        custList.map((c) => {
          if (c.bucketID === b.uid) {
            found = true;
            b.parentId = c.bucketRoot;
            b.isNew = false;
            b.tempSeq = c.sequence;
            b.extId = c.uid;
            newBuckets[b.parentId].push(b);
          }
          return c;
        });

        if (!found) {
          let lastSeq = newBuckets[b.parentId].length;
          b.tempSeq = lastSeq;
          b.isNew = true;
          newBuckets[b.parentId].push(b);
        }
      }
      return b;
    });

    // reconstruct array & sequence
    newBuckets.map((set) => {
      if (set && set.length > 0) {
        // sort buckets
        set.sort(function (a, b) {
          let seqA = a.tempSeq;
          let seqB = b.tempSeq;
          if (seqA < seqB) return -1;
          if (seqA > seqB) return 1;
          return 0;
        });
        // add into array
        set.map((s, i) => {
          if (s.menuType === 'SUBBUCKET') s.sequence = i;
          custBuckets.push(s);
          return s;
        });
      }
      return set;
    });
  }
  return custBuckets;
};

export const updateOpenOrderEnum = (enums, buckets) => {
  let priority = 0;
  let parentText = '';
  if (buckets.length > 0) {
    let newEnums = {};
    buckets.map((b) => {
      if (b.menuType === 'BUCKET') {
        parentText = b.menuLabel;
        priority = b.sequence;
      }

      if (b.menuType === 'SUBBUCKET') {
        newEnums[b.menuIcon] = {
          // menuIcon is enum key name
          value: parseInt(b.menuCode),
          code: 'd' + parseInt(b.menuCode),
          label: b.menuLabel,
          action: b.menuOpenMethod === 'A',
          parentId: b.parentId,
          priority: priority,
          priorityTxt: parentText,
        };
      }
      return b;
    });
    enums.openOrderBucket = newEnums;
  }

  console.log(enums.openOrderBucket);
};

export const updateWriteSalesEnum = (enums, buckets) => {
  let priority = 0;
  let parentText = '';
  if (buckets.length > 0) {
    let newEnums = {};
    buckets.map((b) => {
      if (b.menuType === 'BUCKET') {
        parentText = b.menuLabel;
        priority = b.sequence;
      }
      if (b.menuType === 'SUBBUCKET') {
        newEnums[b.menuIcon] = {
          // menuIcon is enum key name
          value: parseInt(b.menuCode),
          code: 'w' + parseInt(b.menuCode),
          label: b.menuLabel,
          action: b.menuOpenMethod === 'A',
          parentId: b.parentId,
          priority: priority,
          priorityTxt: parentText,
        };
      }
      return b;
    });
    enums.writeSalesBucket = newEnums;
  }
  console.log(enums.writeSalesBucket);
};

export const updateRepeatSalesEnum = (enums, buckets) => {
  let priority = 0;
  let parentText = '';
  if (buckets.length > 0) {
    let newEnums = {};
    buckets.map((b) => {
      if (b.menuType === 'BUCKET') {
        parentText = b.menuLabel;
        priority = b.sequence;
      }
      if (b.menuType === 'SUBBUCKET') {
        newEnums[b.menuIcon] = {
          // menuIcon is enum key name
          value: parseInt(b.menuCode),
          code: 'r' + parseInt(b.menuCode),
          label: b.menuLabel,
          action: b.menuOpenMethod === 'A',
          parentId: b.parentId,
          priority: priority,
          priorityTxt: parentText,
        };
      }
      return b;
    });
    enums.repeatSalesBucket = newEnums;
  }
  console.log(enums.repeatSalesBucket);
};

export const removeSpecialChar = (text) => {
  return text.replace(/[^a-zA-Z0-9]/g, '');
};

export const frameworkEnableExpandBuckets = () => {
  try {
    let parameters = JSON.parse(localStorage.getItem('Parameters'));
    return parseInt(parameters.EXPAND_PANEL || 0);
  } catch (e) {
    return 0;
  }
};

export const checkImage = () => {
  setTimeout(function () {
    $('img').on('error', function () {
      $(this).attr('src', '/img/noimg2.png');
      // $(this).attr('src', '/img/imgnotavailable.png');
    });
  }, 1000);
};

export const checkImgError = (img) => {
  img.src = '/img/noimg2.png';
  // img.src = "/img/imgnotavailable.png";
};

export const getProductCategory = (category) => {
  let parameters = JSON.parse(localStorage.getItem('Parameters'));
  let match = {};
  if (parameters['WhatHot_' + category.replace(/\s+/g, '_')] !== undefined) {
    match = parameters['WhatHot_' + category.replace(/\s+/g, '_')].split(',');
  } else {
    match = undefined;
  }
  console.log(match);

  return match;
};

export const catchServer = (res) => {
  if (res?.server) {
    console.log('APP Server', res.server);
    localStorage.setItem('APP_SERVER', res.server);
  }
  if (
    !localStorage.getItem('WEB_SERVER') ||
    localStorage.getItem('WEB_SERVER') !== process.env.REACT_APP_SERVER
  ) {
    localStorage.setItem('WEB_SERVER', process.env.REACT_APP_SERVER);
  }
};

export const renderPriceFormat = (price) => {
  if (price === undefined) return;

  let priceStr = '';
  if (typeof price === 'string') {
    priceStr = price.replace('$', '');
    priceStr = priceStr.replace(/,/gi, '');

    priceStr = formatNumber(parseFloat(priceStr), 2, '$');
  }
  if (typeof price === 'number') {
    priceStr = formatNumber(price, 2, '$');
  }

  let strArr = priceStr.split('.');
  return (
    <span
      style={{
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: '#d0281b',
        whiteSpace: 'nowrap',
      }}
    >
      {strArr[0]} <sup>{strArr[1]}</sup>
    </span>
  );
};

export const setTimeZone = () => {
  // Mapping of common abbreviations to IANA zones
  const abbreviationToIANA = {
    PST: 'America/Los_Angeles',
    CST: 'America/Chicago',
    EST: 'America/New_York',
    MST: 'America/Denver',
    MSTNO: 'America/Phoenix', // MST with No DST
  };

  const timezoneAbbreviation = localStorage.getItem('timeZone');

  // Check if the abbreviation exists in the mapping
  if (abbreviationToIANA.hasOwnProperty(timezoneAbbreviation)) {
    let currentIANA = abbreviationToIANA[timezoneAbbreviation];

    console.log(`Timezone ${timezoneAbbreviation} with IANA ${currentIANA}`);
    localStorage.setItem('Timezone IANA', currentIANA);
  } else {
    // Handle cases where the abbreviation is not found
    console.error(
      `Could not find IANA zone for abbreviation: ${timezoneAbbreviation}`,
    );
    localStorage.setItem('Timezone IANA', abbreviationToIANA['EST']);
  }
};

export const getTimeZone = () => {
  return localStorage.getItem('Timezone IANA');
};

export const generateUUID = (() => {
  let counter = 0;
  return () => {
    const timeNow = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        const r = (timeNow + Math.random() * 16) % 16 | 0;
        counter++;
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
    return uuid + counter.toString(16).padStart(4, '0');
  };
})();
