// Import the StateProvider
import { GaugeProvider } from './context/GaugeContext';
import React, { useEffect, useState } from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Fab } from '@mui/material';

// Layout
import { EmptyLayout, LayoutRoute, MainLayout } from './framework-layout';

// Custom Component
import {
  STATE_LOGIN,
  STATE_LOGOUT,
} from './application-center/login-center/authForm';
import PageSpinner from './components/PageSpinner';
import ChatbotContainer from './bdf-applications/chatbots';

// SignalR
import SignalRContext from './application-center/signalr-hub-center/singalr-context';
import useSignalRConnection from './application-center/signalr-hub-center/signalr-connection';

// SCSS
import './styles/reduction.scss';
import './bdf-applications/chatbots/chatbot.scss';

// Icons
import { FaComments } from 'react-icons/fa6';

// Routing
const RoutesShared = require('./routes');
const Routes_setup = require('./routes-setup');
const routes = [...RoutesShared.routes, ...Routes_setup.routes];

const uxlog = require('./application-center/ux-tracking-center');

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

// Global variables for BobBoost
window.podiumWindow = null;
window.openedWindow = null;
window.openedOrder = '';
window.podiumOrder = '';

// module, to hold the page object
window.message = {};
window.redirect = {};
window.BDFModules = {
  write: null,
  deliver: null,
  customers: null, // for future use
};

window.enums = {};
window.orderSchedule = null;
window.cncscheduler = null;

const App = ({ breakpoint }) => {
  useEffect(() => {
    uxlog.sendUXLogScheduler();
    console.log('UXLog started');
  }, []);

  const appToken = localStorage.getItem('appToken');
  const { connection } = useSignalRConnection(appToken);

  const [chatbotOpen, setChatbotOpen] = useState(false);

  return (
    <GaugeProvider>
      {/* Wrap everything inside StateProvider */}
      <SignalRContext.Provider value={connection}>
        <BrowserRouter basename={getBasename()}>
          <Switch>
            <LayoutRoute
              exact
              path="/ssolanding"
              layout={EmptyLayout}
              component={(props) => <RoutesShared.LandingPage {...props} />}
            />

            <LayoutRoute
              exact
              path="/legacy"
              layout={EmptyLayout}
              component={(props) => (
                <RoutesShared.LandingLegacyPage {...props} />
              )}
            />

            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={(props) => (
                <RoutesShared.LoginCenter {...props} authState={STATE_LOGIN} />
              )}
            />

            <LayoutRoute
              exact
              path="/logout"
              layout={EmptyLayout}
              component={(props) => (
                <RoutesShared.LoginCenter {...props} authState={STATE_LOGOUT} />
              )}
            />

            <LayoutRoute
              exact
              path="/orderTimeline"
              layout={EmptyLayout}
              component={(props) => (
                <RoutesShared.ExternalAccess page="order-timeline" {...props} />
              )}
            />

            <LayoutRoute
              exact
              path="/iframe"
              layout={EmptyLayout}
              component={(props) => <RoutesShared.ExternalTest {...props} />}
            />

            <LayoutRoute
              exact
              path="/tabletviewhelp"
              layout={EmptyLayout}
              component={(props) => <RoutesShared.TabletHelp {...props} />}
            />

            <MainLayout breakpoint={breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <Route exact path="/" component={RoutesShared.DashboardPage} />
                <Route
                  exact
                  path="/home"
                  component={RoutesShared.DashboardPage}
                />
                <Route
                  exact
                  path="/home/:section"
                  component={RoutesShared.DashboardPage}
                />

                {routes.map((c, i) => (
                  <Route exact key={i} path={c.path} component={c.component} />
                ))}

                {[
                  '34',
                  '186',
                  '386',
                  '306',
                  '110',
                  '182',
                  '32',
                  '410',
                  '408',
                  '404',
                  '406',
                ].includes(localStorage.getItem('StoreId')) && (
                  <>
                    <Fab
                      color="primary"
                      aria-label="chat"
                      className={`chat-fab ${chatbotOpen ? 'chat-open' : ''}`}
                      onClick={() => setChatbotOpen(!chatbotOpen)}
                    >
                      <FaComments size={25} />
                    </Fab>

                    <ChatbotContainer
                      open={chatbotOpen}
                      onClose={() => setChatbotOpen(false)}
                    />
                  </>
                )}
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      </SignalRContext.Provider>
    </GaugeProvider>
  );
};

const query = ({ width }) => {
  if (width <= 575) {
    return { breakpoint: 'xxs' };
  }
  if (576 <= width && width < 767) {
    return { breakpoint: 'xs' };
  }
  if (768 <= width && width < 991) {
    return { breakpoint: 'sm' };
  }
  if (992 <= width && width < 1199) {
    return { breakpoint: 'md' };
  }
  if (1200 <= width && width < 1920) {
    return { breakpoint: 'lg' };
  }
  return { breakpoint: 'xl' };
};

export default componentQueries(query)(App);
