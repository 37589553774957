/**
 * Service class for handling browser push notifications, sound alerts, and title flashing.
 * Provides methods for requesting notification permissions, showing notifications,
 * playing sounds, and creating visual alerts through title flashing.
 */
class PushNotificationService {
  /**
   * Initializes a new PushNotificationService instance.
   * Sets up initial state for notifications, sound path, and title flashing.
   */
  constructor() {
    this.currentNotification = null;
    this.soundPath = '/audio/notification_sound.wav';
    this.currentFlashingInterval = null;
  }

  /**
   * Requests permission from the user to show browser notifications.
   * @returns {Promise<boolean>} True if permission is granted, false otherwise.
   */
  async requestPermission() {
    if (
      Notification.permission !== 'granted' &&
      Notification.permission !== 'denied'
    ) {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    }
    return Notification.permission === 'granted';
  }

  /**
   * Displays a browser notification with the specified title and options.
   * Closes any existing notification before showing a new one.
   * @param {string} title - The title of the notification
   * @param {Object} options - Notification options following the Notification API spec
   * @param {string} [options.tag='default-tag'] - Unique identifier for the notification
   * @param {boolean} [options.requireInteraction=false] - Whether notification requires user interaction to dismiss
   * @returns {Promise<boolean>} - Returns true if notification was successfully shown
   */
  async showNotification(title, options = {}) {
    const defaultOptions = {
      tag: 'default-tag',
      requireInteraction: false,
      silent: false,
    };

    try {
      const permissionGranted = await this.requestPermission();
      if (permissionGranted) {
        // Close previous notification if it exists
        if (this.currentNotification) {
          this.currentNotification.close();
        }

        // Create the notification with a promise wrapper to handle potential issues
        return new Promise((resolve, reject) => {
          try {
            this.currentNotification = new Notification(title, {
              ...defaultOptions,
              ...options,
            });

            // Handle notification state changes
            this.currentNotification.onshow = () => resolve(true);
            this.currentNotification.onerror = (error) => reject(error);

            // Set a timeout in case the onshow event doesn't fire
            setTimeout(
              () =>
                !this.currentNotification.onshow &&
                reject('Notification may not have been shown (timeout)'),
              1000,
            );
          } catch (error) {
            reject(error);
          }
        });
      }
      return false;
    } catch (error) {
      console.error('Unexpected error in showNotification:', error);
      return false;
    }
  }

  /**
   * Plays a notification sound using the configured audio file.
   * @returns {Promise<void>} Promise that resolves when the sound starts playing
   */
  playSound() {
    const audio = new Audio(this.soundPath);
    return audio.play().catch((error) => {
      console.error('Error playing notification sound:', error);
    });
  }

  /**
   * Creates a flashing title effect by alternating between the original and custom title.
   * @param {string} customTitle - The alternate title to flash
   * @param {number} [duration=5000] - Total duration of flashing in milliseconds
   * @param {number} [interval=500] - Interval between title changes in milliseconds
   * @returns {Function} Cleanup function to stop the flashing effect
   */
  flashTitle(customTitle, duration = 5000, interval = 500) {
    const originalTitle = 'BobBoost';
    let isFlashing = true;
    let flashingInterval = null;

    // Clear any existing flashing interval
    if (this.currentFlashingInterval) {
      clearInterval(this.currentFlashingInterval);
      document.title = originalTitle;
    }

    // Start new flashing interval
    flashingInterval = setInterval(() => {
      if (isFlashing) {
        document.title =
          document.title === originalTitle ? customTitle : originalTitle;
      } else {
        clearInterval(flashingInterval);
        document.title = originalTitle;
      }
    }, interval);

    // Store current flashing interval
    this.currentFlashingInterval = flashingInterval;

    // Stop flashing after duration
    setTimeout(() => {
      isFlashing = false;
      clearInterval(flashingInterval);
      document.title = originalTitle;
      this.currentFlashingInterval = null;
    }, duration);

    // Return cleanup function
    return () => {
      isFlashing = false;
      if (flashingInterval) {
        clearInterval(flashingInterval);
        document.title = originalTitle;
      }
      this.currentFlashingInterval = null;
    };
  }

  /**
   * Shows a notification for GuestConnect position changes.
   * @param {number} position - The current position in the queue
   * @description
   * Shows a notification with the current position in the GuestConnect queue.
   * If the position is 1 or 2, a special message is shown. Otherwise, the exact
   * position is shown. The notification is also accompanied by a brief title
   * flash and a notification sound.
   */
  async notifyGuestConnectPositionChange(position) {
    const title = 'GuestConnect Position Update';
    const message =
      position === 1
        ? "You're next in line!"
        : position === 2
          ? "You're second in line!"
          : `Your current position is ${position}`;

    // This gives the browser more time to process UI changes before showing the notification
    const delayTime = 1000;
    await new Promise((resolve) => setTimeout(resolve, delayTime));

    // Always play sound notification first
    // this.playSound();

    // Always flash title
    this.flashTitle(`🔔 Position ${position} - GuestConnect 🔔`, 5000);

    const notificationShown = await this.showNotification(title, {
      body: message,
      tag: 'guest-connect-position',
      requireInteraction: false,
    });

    // Log whether notification was shown
    console.log(
      `Browser notification ${notificationShown ? 'shown' : 'may not have been shown'}`,
    );
  }
}

// Create a singleton instance for global use
const pushNotificationService = new PushNotificationService();
export default pushNotificationService;
